import {
  get,
  post,
  postExcel,
  del,
  put,
  upload
} from "./http";

const axios = {
  // 登陆
  login: data => {
    return post("/login", data);
  },
  getBasicType: data => {
    return get("/identify/company/basicType", data);
  },
  getUserInfo: data => {
    return get("/user/info", data);
  },
  getBankCode: () => {
    return get('/bank/bankCode');
  },
  getIdentifyInfo: (data) => {
    return get('/identify/company/identifyInfo', data);
  },
  // 统一上传
  Upload(data) {
    return post('/attachment-info/presigned-upload-url', data)
  },
  // 上传到OSS服务器
  uploadToServer(url, md5Base64, file, contentType) {
    return upload(url, md5Base64, file, contentType)
  },
  // 企业新增实名信息
  companyIdentify: data => {
    return post("/identify/company/identify", data);
  },
  // 新增和修改签约详细信息
  chinaumsComplexUpload: data => {
    return post("/chinaumsComplexUpload", data);
  },
  // 合利宝发短信
  payBindCardPreOrder(data) {
    return post('/heliMerchant/payBindCardPreOrder', data);
  },
  // 合利宝鉴权
  payBindCard(data) {
    return post('/heliMerchant/payBindCard', data);
  },
  // 企业开户相关图片信息提交
  summaryComplexForEnterprise: data => {
    return get("/chinaumsComplexUpload/summaryComplexForEnterprise");
  },
  // 数据提交
  complexUpload: data => {
    return get("/chinaumsComplexUpload/complexUpload");
  },
  // 开通店铺
  createUserShop: data => {
    return post("/userShop", data);
  },
  // 获取店铺信息
  getUserShop: data => {
    return get("/userShop", data);
  },
  //新增图片上传
  chinaumsPicUpload: data => {
    return post("/chinaumsPicUpload", data)
  },
  // 查询银行支行
  getBranchBankList: data => {
    return get("/chinaumsComplexUpload/getBranchBankList", data)
  },
  // 获取银行列表 /bank/bankCode
  getBankCode: data => {
    return get("/bank/bankCode")
  },
  // 发送手机验证码
  sendCode(data) {
    return get('/mobile/' + data.mobile, data);
  },
  // 上传企业信息
  companyWalletFirst(data) {
    return post('/heliMerchant/companyWalletFirst', data);
  },
  // 第四步提交企业凭证信息
  companyWalletSecond(data) {
    return post('/heliMerchant/companyWalletSecond', data);
  },
  // 查詢heli /heliMerchant/signContract
  signContract() {
    return post('/heliMerchant/signContract', {});
  },
  
  // 获取审核状态
  getComplexUpload(data) {
    return get('/chinaumsComplexUpload/getComplexUpload', data);
  },
  // 企业对公账号验证发起请求
  requestAccountVerify() {
    return get('/chinaumsComplexUpload/requestAccountVerify');
  },
  // 企业对公账号验证
  companyAccountVerify(data) {
    return get('/chinaumsComplexUpload/companyAccountVerify', data);
  },
  // 获取合利宝签约状态 /heliMerchant/contractResult 
  getContractResult(data) {
    return get('/heliMerchant/contractResult', data);
  },
  // 企业对公账号验证
  getAreaGetCounty() {
    return get('/area/getCounty');
  },
  // 获取密匙
  getPublicKey() {
    return get('/token/getPublicKey');
  },
  // 获取密匙
  loginByUsername(data) {
    return get('/oauth/token', data);
  },
  
}

export default axios;